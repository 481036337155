import React, { FC, useContext } from "react";
import { Context, IAsset } from "src/common";

import { NewsContainer, NewsWrapper } from "./NewSlider.style";
import NewSlide from "./slide/NewSlide";

const NewSlider: FC<{ limit?: number; newsCustom?: boolean }> = ({ limit, newsCustom }) => 
{
  const p = useContext(Context);
  const news = newsCustom ? p?.PageLanding?.NewsCustom : p.PageLanding?.News;
  const newsSlug = p.NewsSlug;
  if(!news || news.length <= 0) 
  {
    return null;
  }

  let newsToRender = news?.filter(item => 
  {
    if(!item) 
    {
      return false;
    }
    if(!item?.startDate && !item?.endDate) 
    {
      return true;
    }
    else if(!item?.startDate && item?.endDate) 
    {
      return new Date() <= new Date(item?.endDate);
    }
    else if(item?.startDate && !item?.endDate) 
    {
      return new Date() >= new Date(item?.startDate);
    }
    else 
    {
      return (
        new Date() >= new Date(item?.startDate)
        && new Date() <= new Date(item?.endDate)
      );
    }
  });

  if(limit) { newsToRender = newsToRender.slice(0, 4); }

  return (
    <NewsWrapper>
      {!newsCustom && <h2>AKTUELLES</h2>}

      <NewsContainer>
        {newsToRender.map((NewsItem, i) => (
          <>
            { NewsItem &&
              <NewSlide
                key={i + NewsItem?.id}
                index={i}
                totalCount={newsToRender.length}
                img={(NewsItem?.extras as IAsset[])}
                imgAlt={(NewsItem?.extras as IAsset[])?.[0]?.title}
                title={NewsItem?.headline}
                text={NewsItem?.text}
                link={newsSlug && (newsSlug + "/" + NewsItem?.id)}
              />
            }
          </>
        ))}
      </NewsContainer>

      
    </NewsWrapper>
  );
};

export default NewSlider;
