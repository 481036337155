// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Slide: any = styled.div`
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  background-color: #bcbcbc;
  margin: 10px;

  ${({ withImage }) => withImage && "justify-content: flex-start;"}
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% - 40px);
    margin: 10px auto;
    flex-direction: row;
    height: 250px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: calc(50% - 20px);
    height: 200px;
  }
`;

export const ContentContainer: any = styled.div`
  position: relative;
  padding: 20px;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
  height: 125px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    height: auto;
  }
`;

export const Content: any = styled.div`
    overflow: hidden;

    > a {
      color: ${({ theme }) => theme.palette.textColor};

      > h3 {
        margin: 0;
        font-family: "Roboto Slab";
        text-decoration: none;
        font-weight: 800;
        font-size: 18px!important;
        line-height: 1.46667;

      }

      > div {
        &,
        * {
          margin: 0;
          font-family: "Nunito Sans";
          font-size: 18px;
          line-height: 28px;
          font-weight: 300;
       }
      }

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: ${({ theme }) => theme.palette.primary};
        color: ${({ theme }) => theme.palette.white};
        height: 50px;
        width: 50px;
      }
    }
`;

export const ImageContainer: any = styled.a`
    object-fit: cover;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
    }
`;
