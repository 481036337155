import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { TSharedStylesOjb } from "../LandingImage";

interface IProps {
    sharedStylesOjb: TSharedStylesOjb;
}

const Default = css<IProps>`
    overflow: hidden;
    width: 100%;
    position: relative;

    ${({ sharedStylesOjb: { heightDefault, headerHeight } }) => css`
        height: calc(100vw + ${headerHeight}px + 66px);
    `}
`;

const Silver = css<IProps>`
    ${({ sharedStylesOjb: { heightSliver, headerHeight } }) => css`
        height: calc(${heightSliver}vh - ${headerHeight}px);
    `}
`;

const Gold = css``;

const Platinum = css<IProps>`
    ${({ sharedStylesOjb: { heightPlatinum, headerHeight } }) => css`
        height: calc(${heightPlatinum}vh - ${headerHeight}px);
    `}
`;

const Diamond = css``;

export const SLandingImage = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
