import React, { FC } from "react";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import { Container } from "../Grid";
import CompanyInfo from "./CompanyInfo";
import { ContactDataWrapper } from "./Contact.style";
import OfficeInfo from "./OfficeInfo";

const ContatData: FC = () => {
  return (
    <>
      <Container>
        <h3>{getContactCompanyNameShort()}</h3>
      </Container>
      <ContactDataWrapper>
        <CompanyInfo />
        <OfficeInfo />
      </ContactDataWrapper>
    </>
  );
};

export default ContatData;
