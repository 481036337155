import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container } from "../Grid";

export const MapWrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
  }
`;

export const MapContainer: any = styled.div`
    filter: grayscale(100%) sepia(5%) hue-rotate(-20deg);
    height: 500px;
    width: 100%;
    margin-top: 2.5rem;
    @media (${BREAKPOINTS_DM.silver_768}) {
    }
`;

export const serviceNumber: any = css`
    margin-bottom: 50px;
    align-items: flex-start;
    padding-left: 10px;
`;

export const RouteWrapper: any = styled.div`
    margin-top: 30px;

    label {
      font-weight: 700;
      line-height: 1.7em;
    }

    > div {
      display: flex;
      justify-content: center;

      input,
      button {
        color: ${({ theme }) => theme.palette.textColor};
        margin-right: 14px;
        border: 1px solid #796662;
        padding: 6px 10px;
        font-size: 13px;
        border-radius: 0;
        max-width: 45%;
        display: inline-block;
        line-height: normal;
        height: 30px;
      }

      button {
        :after {
          transform: rotate(-90deg);
          content: '';
          background: transparent url(/images/icon-arrow.svg) 0 0 no-repeat;
          display: inline-block;
          position: relative;
          top: -.0625rem;
          width: 1rem;
          height: .5rem;
          right: -.3125rem;
        }

        :hover {
          background-color: #796662;
          color: ${({ theme }) => theme.palette.white};
          :after {
            background: transparent url(/images/icon-arrow-white.svg) 0 0 no-repeat;
          }
        }
      }
    }
`;

export const LocationContainer: any = styled.div`
padding: 10.5px;
p {
  }
`;

export const DirectionsPanel: any = styled.div`
  margin-top: 30px;
  table.adp-placemark {
    border: 0;
    background: #796662;
    margin: 10px 0px 10px 0px;
    vertical-align: middle;
    width: 100%;
    tr {
      td {
        img.adp-marker2 {
          display: none;
        }
        color: #fff;
        padding: .125rem .3125rem;
        font-size: 13px;
      }
    }
  }

  .adp-summary {
    width: 100%;
    padding: 0 3px 3px 3px;
    span {
      font-weight: 700;
      color: #2C2C2C;
      font-size: 13px;
    }
  }
  table:not(.adp-placemark){
    width: 100%;
    tr {
      td {
        border-top: 1px solid #796662;
        padding: .5rem .625rem;
        text-align: left;
        color: #2C2C2C;

        &:first-child {
          padding-left: 5px;
          width: 10% !important;
        }
        &:nth-child(2) {
          width: 5% !important;
          font-weight: 500;
          font-size: 14px;
        }
        &:nth-child(3) {
          width: 70% !important;
          font-weight: 500;
        }
        &:nth-child(4) {
          width: 15% !important;
          text-align: right;
        }
      }
    }
  }
`;

export const catchmentArea: any = css`
`;

export const AddressFormContainer: any = styled(Container)`
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      padding: 0 30px;
    }
`;
