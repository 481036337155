import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import {  H1_1366_Bold, H1_1366_Regular, H1_360_Bold, H1_360_Regular, H1_768_Bold, H1_768_Regular } from "src/templates/t27/constants/typography";

const Default = css`
    position: absolute;
    z-index: 2;
    max-width: calc(100% - 48px);
    bottom: 24px;
    left: 24px;
    color: var(--front-2);

    > h1,
    > div > * {
        background-color: var(--back-1);
        padding: 6px 12px;
        display: inline-block;
    }

    > h1 {
        margin-bottom: 12px;
        ${H1_360_Bold}
    }

    > div > * {
        ${H1_360_Regular}
    }
`;

const Silver = css`
    > h1 {
        ${H1_768_Bold}
    }

    > div > * {
        ${H1_768_Regular}
    }
`;

const Gold = css``;

const Platinum = css`
    max-width: calc(100% - 152px);
    bottom: 40px;
    left: 76px;

    > h1,
    > div > * {
        padding: 8px 20px;
    }

> h1 {
        ${H1_1366_Bold}
        margin-bottom: 19px;
    }

    > div > * {
        ${H1_1366_Regular}
    }
`;

const Diamond = css``;

export const SLandingImageHeading = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
