import { css } from "styled-components";

export default {
  h1: css`
    margin: 0 0 21px 0;
    color: ${({ theme }) => theme.palette.green};
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  `,
  h3: css`
      margin: 0 0 21px 0;
      color: ${({ theme }) => theme.palette.green};
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
  `,
  // p h2 h3 etc etc
};
