import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../RichText/RichText";
import { Slide, ContentContainer, Content, ImageContainer } from "./NewSlide.style";

interface IProps
{
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

const NewSlide: FunctionComponent<IProps> = props =>
{
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find(p => p.type.includes("heading"));
  if(elementToShowAsSubtitle)
  {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <Slide withImage={!!props.img}>
      {props.img && (
        <ImageContainer href={props?.link}>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
        </ImageContainer>
      )}

      <ContentContainer>
        <Content>
          <a href={props?.link}>
            {props.title && <h3>{props.title}</h3>}
            {text && (<RichText textContent={text} />)}
            <span><i className="fa fa-chevron-right" /></span>
          </a>
        </Content>
      </ContentContainer>
    </Slide>
  );
};

export default NewSlide;
