import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FormWrapper: any = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-top: 115px;
  }

  strong {
    font-weight: 600;

  }
  p {
    font-weight: 400;
    span {
      font-weight: 400;
    }
  }

  h3 {
    color: ${props => props.theme.palette.textColorHeadings};
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.25rem;
    text-align: center;
    flex: 1;
  }

  table {
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: inline-block;
      width: 100%;
    }
    tr {
      flex-direction: column;
      display: flex;
      @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
      }

      td {
        width: 100%;
        p {
          padding: 0;
        }
      }
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
  }
`;

export const CompanyInfoWrapper: any = styled.div`
  width: 50%;
  text-align: center;
  * {
    font-weight: 300;
  }

  p {
    margin-bottom: 10px;
  }
`;

export const OfficeInfoWrapper: any = styled.div`
  width: 50%;
  text-align: center;
  p {
    margin-bottom: 10px;
    &.c-empty {
      min-height: 0;
      margin: 0;
    }
  }

  table {
    tr {
      align-items: center;
      td {
        padding: 0;
        margin: 0;
        width: 50% !important;
        p {
          &, span {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  a {
    display: inline-block;
    margin-top: 20px;
    margin-right: 1.25rem;
    svg {
      height: 30px;
      width: 30px !important;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0 60px;
  }
`;

export const directionsDescription: any = css`
  h6 {
      font-size: 19px;
  }
`;

export const contact: any = css`
  margin-top: 20px;
`;

export const SidebarText: any = styled.div`
  background-color: rgba(255,255,255,0.2);
  margin-top: 30px;
  padding: 10px 10px 15px;
  border-radius: 8px;
  max-width: 250px;
  br:first-child {
    margin-top: 0;
  }
  div > p:last-child {
    margin-bottom: 0;
  }
  strong p {
    margin-bottom: 0;
  }
`;

export const ContactDataWrapper: any = styled.div`
  width: 100%;
  padding: 50px 30px 0 30px;
  display: flex;
  justify-items:
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 4);
  }
`;

export const ContactForm: any = styled.div`
  width: 100%;
  padding: 0 30px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-bottom: 42px;
  }

  label {
    font-weight: 300;
    font-size: 14px;
    color: #777878;
  }
`;

export const InputWrapper: any = styled.div`
  display: flex;

  input {
    height: 37px;
    display: block;
    width: 100%;
    border-radius: 0;
    border: 1px solid #505050;
    padding: .5rem;
    font-size: .875rem;
    color: #777878;
  }

  span {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;

    :last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;

export const InputsWrapper: any = styled.div`
  width: 100%;
  label, input, textarea {
    width: 100%;
    display: block;
  }

  textarea {
    height: 200px;
    display: block;
    width: 100%;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #505050;
    padding: .5rem;
    font-size: .875rem;
    color: #777878;
  }

  label:not([for=Name]) {
    margin-top: 21px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    label[for="E-Mail"] {
      margin-top: 0;
    }

    label:not([for=Name]):not([for="E-Mail"]) {
      margin-top: 21px;
    }
  }
`;

export const SubmitWrapper: any = styled.div`
  display: flex;
  justify-content: space-between;
  height: 87px;
  margin-top: 21px;

  > div {
    display: flex;
    align-items: flex-start;
    font-weight: 600;

    > label > a {
      font-weight: 600;
    }
  }

  button {
    color: ${({ theme }) => theme.palette.textColor};
    margin-right: 14px;
    border: 1px solid #796662;
    padding: 6px 10px;
    font-size: 13px;
    border-radius: 0;
    max-width: 45%;
    display: inline-block;
    line-height: normal;
    height: 30px;

    :after {
      transform: rotate(-90deg);
      content: '';
      background: transparent url(/images/icon-arrow.svg) 0 0 no-repeat;
      display: inline-block;
      position: relative;
      top: -.0625rem;
      width: 1rem;
      height: .5rem;
      right: -.3125rem;
    }

    :hover {
      background-color: #796662;
      color: ${({ theme }) => theme.palette.white};
      :after {
        background: transparent url(/images/icon-arrow-white.svg) 0 0 no-repeat;
      }
    }
  }
`;

export const datenschutzAkzeptierenWrapper: any = css`
  display: flex;
  max-width: 50%;
`;

export const datenschutzAkzeptieren: any = css`
  font-size: 12px;
  margin-left: 5px;
  a {
    color: ${props => props.theme.palette.primary};
  }
`;

export const ImageWrapper: any = styled.div`
  width: 100%;
  object-fit: contain;
  margin: 0 auto 60px auto;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 890px;
    height: 810px;
  }
`;
