import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const CompanyInformationWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  color: #fff;
  div.content {
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: row;
    }
    margin-top: 20px;
  }
`;

export const Address: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  color: #fff;
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
  span.phone {
    margin-top: 10px;
  }
  span.phone-number {
    color: #000;
    font-weight: 300;
    &:hover {
      color: #777878;
    }
  }
`;

export const OfficeInfo: any = styled.div`

`;

export const RichTextWrapper: any = styled.div`
    margin-top: -36px;
    p > span {
      letter-spacing: 0.5px;
    }
`;

export const Logo: any = styled.img`
  align-self: flex-start;
  height: 40px;
`;