// @ts-nocheck
import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Container = styled.div`
  width: 100%;
`;

export const Grid = styled.div`
  display: flex;
  max-width: 950px;
  margin: 0 auto;
`;

export const Section = styled.div`
  width: 100%;
  background-color: #393531;
`;

export const Col: FC<any> = styled.div<IGridProps>`
  width: ${({ sm }) => sm ? 100 / 12 * sm : 100}%;

  @media (${BREAKPOINTS_DM.silver_768}){
    ${({ md }) => md && `width: ${100 / 12 * md}%;`}
  }
`;

export const Row: FC<any> = styled.div<IGridProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ expand }) => expand && `
    margin-left: -10px;
    margin-right: -10px;
  `}

  ${Col} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
