import styled, { css } from "styled-components";

import {
    MIN_SILVER,
    MIN_GOLD,
    MIN_PLATINUM,
    MIN_DIAMOND
} from "src/common/constants/mediaquerys";

interface IProps 
{
    children?: any;
    theme?: any;
    ratioCalcBronze?: number;
    ratioCalcSilver?: number;
    ratioCalcGold?: number;
    ratioCalcPlatinum?: number;
    ratioCalcDiamond?: number;
}

const Default = css`
  padding-top: ${(props: IProps) => props.ratioCalcBronze || 100}%;
  position: relative;
  width: 100%;
  > div {
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
`;

const Silver = css`
  padding-top: ${(props: IProps) => props.ratioCalcSilver || 100}%;
`;

const Gold = css`
  padding-top: ${(props: IProps) => props.ratioCalcGold || 100}%;
`;

const Platinum = css`
  padding-top: ${(props: IProps) => props.ratioCalcPlatinum || 100}%;
`;

const Diamond = css`
  padding-top: ${(props: IProps) => props.ratioCalcDiamond || 100}%;
`;

const SImageWithAspectRatio = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SImageWithAspectRatio;
