import React, { FC, useEffect, useState, useContext } from "react";
import { Context, ITextElement } from "src/common";

import RichText from "../RichText";
import ServiceNumber from "../ServiceNumber";
import * as styles from "./Map.style";
import { MapWrapper, MapContainer, RouteWrapper, LocationInput, DirectionsPanel, AddresWrapper } from "./Map.style";
import CompanyInformation from "./CompanyInformation"
const mapElementId = "map";
const routePanelId = "routePanel";
const googleApiKey = process.env.NEXT_PUBLIC_MAPSAPIKEY || process.env.MAPSAPIKEY;
interface ILocation
{
  lat: number;
  lng: number;
}

export const AddressForm: FC<any> = ({
  title, placeholder, actionCaption, onSetUserLocation, landingPage
}) =>
{
  const [inputUserLocation, setInputUserLocation] = useState("");

  const getUserLocation = (): void =>
  {
    if(navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition(position =>
      {
        setInputUserLocation(`${position.coords.latitude},${position.coords.longitude}`);
      }, error =>
      {
        console.warn("Standort konnte nicht abgerufen werden: ", error);
      });
    }
    else
    {
      console.warn("Standort konnte nicht abgerufen werden");
    }
  };

  return (
    <AddresWrapper landingPage={landingPage}>
      {!landingPage && <p>{title || "Startadresse"}</p>}
      {landingPage && <CompanyInformation/>}
      <RouteWrapper landingPage={landingPage}>
        <LocationInput landingPage={landingPage}>
          <input
            type="text"
            name={"UserLocation"}
            placeholder={placeholder || "Ihr Standort"}
            value={inputUserLocation}
            onChange={({ target: { value } }) => setInputUserLocation(value)}
          />
          <svg onClick={getUserLocation} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M24 11h-2.051c-.469-4.725-4.224-8.48-8.949-8.95v-2.05h-2v2.05c-4.725.47-8.48 4.225-8.949 8.95h-2.051v2h2.051c.469 4.725 4.224 8.48 8.949 8.95v2.05h2v-2.05c4.725-.469 8.48-4.225 8.949-8.95h2.051v-2zm-11 8.931v-3.931h-2v3.931c-3.611-.454-6.478-3.32-6.931-6.931h3.931v-2h-3.931c.453-3.611 3.32-6.477 6.931-6.931v3.931h2v-3.931c3.611.454 6.478 3.319 6.931 6.931h-3.931v2h3.931c-.453 3.611-3.32 6.477-6.931 6.931zm1-7.931c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z" />
          </svg>
        </LocationInput>
        <button onClick={() => onSetUserLocation(inputUserLocation)} name={"submit"}>
          <span>{actionCaption || "Route berechnen"}</span>{landingPage && <i className="fa fa-chevron-right"/>}
        </button>
      </RouteWrapper>

      <DirectionsPanel id={routePanelId} />
    </AddresWrapper>
  );
};

const setMap = (zoom: number, companyLocation: any): void =>
{
  if(!companyLocation) { return; }

  // @ts-ignore
  const windowGoogleMap = window.google.maps;

  const mapId = document.getElementById(mapElementId);
  const map = new windowGoogleMap.Map(mapId, {
    zoom,
    center: companyLocation
  });
  new windowGoogleMap.Marker({ position: companyLocation, map });
};

export const getRouteDescriptions = (zoom, companyLocation: any, userLocation: any): void =>
{
  document.getElementById(routePanelId).innerHTML = null;

  // @ts-ignore
  const windowGoogleMap = window.google.maps;

  const directionsService = new windowGoogleMap.DirectionsService();
  const directionsRenderer = new windowGoogleMap.DirectionsRenderer();

  const map = new windowGoogleMap.Map(document.getElementById(mapElementId));

  directionsRenderer.setMap(map);
  directionsRenderer.setPanel(document.getElementById(routePanelId));

  const request = {
    origin: userLocation,
    destination: `${companyLocation.lat},${companyLocation.lng}`,
    travelMode: "DRIVING"
  };

  directionsService.route(request, (result, status) =>
  {
    if(status === "OK")
    {
      directionsRenderer.setDirections(result);
    }
    else
    {
      setMap(zoom, companyLocation);
    }
  });
};

const injectGmaps = (callback: () => void): any =>
{
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}`;

  const x = document.getElementsByTagName("script")[0];
  x.parentNode.insertBefore(s, x);
  s.addEventListener("load", callback);

  return () => s.removeEventListener("load", callback);
};

const Map: FC<any> = ({ landingPage }) =>
{
  const props = useContext(Context);
  const companyLocation = props.PageContact && {
    lat: props.PageContact?.location.latitude,
    lng: props.PageContact?.location.longitude,
  };

  const zoom = props.PageContact?.location?.zoom || 12;

  useEffect(() =>
  {
    if(!companyLocation)
    {
      return;
    }

    const callback = (): void => setMap(zoom, companyLocation);

    // @ts-ignore
    if(window.google)
    {
      callback();
      return;
    }

    return injectGmaps(callback);
  }, [zoom, companyLocation]);

  const textElements = (props.PageContact?.elements?.filter(e => e?.__typename === "TextElement")) as ITextElement[];
  return (
    <MapWrapper landingPage={landingPage}>
      <MapContainer id={mapElementId} landingPage={landingPage}/>
      <AddressForm
        landingPage={landingPage}
        zoom={zoom}
        title={props.UniversalTexts?.StartAddress }
        placeholder={props.UniversalTexts?.yourLocation}
        actionCaption={props.UniversalTexts?.CalculateRoute}
        onSetUserLocation={(userLocation) => getRouteDescriptions(zoom, companyLocation, userLocation)}
      />
      {textElements?.[0] &&
        <RichText textContent={textElements?.[0]?.text} >
          <h1>{textElements?.[0]?.headline}</h1>
        </RichText>
      }
    </MapWrapper>
  );
};

export default Map;
