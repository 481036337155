import React, { useContext, FunctionComponent } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { Context, ISidebarText, getPage, ITextElement, IAsset, Img } from "src/common";
import { handleFormSubmit } from "src/common/utils/formSubmit";

import { Col, Row } from "../Grid";
import * as styles from "./Contact.style";
import { FormWrapper, ContactForm, InputsWrapper, InputWrapper, SubmitWrapper, ImageWrapper } from "./Contact.style";
import ContactData from "./ContactData";

const Contact: FunctionComponent = () => 
{
  const props = useContext(Context);
  const page = getPage(props);
  const {
    register,
    handleSubmit,
    errors,
    reset,
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [success, setSuccess] = React.useState(false);

  const onSubmit = async (data): Promise<any> => 
  {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441
    let title: string | undefined;
    if(typeof window !== "undefined") 
    {
      title = document.title;
    }
    await handleFormSubmit({
      ...data,
      line: props?.CompanyInformationPrivate?.line,
      subject: `Neue Anfrage über Ihre Homepage ${data?.Name ? `von ${data?.Name} ` : ""}${title ? `- ${title}` : ""}`,
      dealerMail: props?.CompanyInformationPublic?.email,
      templateId: "d-26922068a30343d98a725c3fdaa663da"
    });

    reset();
    setSuccess(true);
  };

  const name = props.UniversalTexts?.name || "Name";
  const eMail = props.UniversalTexts?.eMail || "E-Mail";
  const message = props.UniversalTexts?.message || "Nachricht";

  const sidebarText = props.PageContact?.extras?.find(
    extraItem => extraItem?.__typename === "SidebarText"
  ) as ISidebarText;

  // @ts-ignore
  const imgArray = page?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const textElements = page?.elements?.filter(item => item?.__typename === "TextElement") as ITextElement[];

  textElements?.map(textElement => 
  {
    if(textElement?.icon) 
    {
      textElement?.icon?.map(icon => imgArray.push(icon as any));
    }
  });

  return (
    <FormWrapper>
      <ContactForm>
        {props?.UniversalTexts?.contactForm && (
          <h3>{props?.UniversalTexts?.contactForm}</h3>
        )}
        {success ? (
          <div>
            <h1>DANKE!</h1>
          Wir werden uns sobald wie möglich bei Ihnen melden.
          </div>
        )
          :
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputsWrapper>
              <Row fullWidth>
                <Col bronze={[1, 4, 1]} silver={[1, 4]} gold={[1, 6]}>
                  <label htmlFor={"Name"}>{name}</label>
                  <InputWrapper>
                    <input
                      required={true}
                      type="text"
                      name="Name"
                      ref={register({
                        options: { required: true, unique: true },
                        min: 12
                      })}
                    />
                  </InputWrapper>
                </Col>

                <Col bronze={[1, 4, 2]} silver={[5, 8]} gold={[7, 12]}>
                  <label htmlFor={"E-Mail"}>{eMail}</label>
                  <InputWrapper>
                    <input
                      required={true}
                      type="email"
                      name="E-Mail"
                      ref={register({
                        options: { required: true, unique: true },
                        min: 4
                      })}
                    />
                  </InputWrapper>
                </Col>
              </Row>
              <Row fullWidth>
                <Col bronze={[1, 4]} silver={[1, 8]} gold={[1, 12]}>
                  <label htmlFor={"Nachricht"}>{message}</label>
                  <textarea
                    rows={8}
                    required={true}
                    name="Nachricht"
                    ref={register({ required: true })}
                  />
                </Col>
              </Row>
            </InputsWrapper>
            <SubmitWrapper>
              <div>
                <input
                  required={true}
                  type="checkbox"
                  placeholder="Ich akzeptiere die Datenschutzerklärung"
                  name="checkbox"
                  ref={register({ required: true })}
                />
                <label
                  htmlFor={"checkbox"}
                  css={styles.datenschutzAkzeptieren}
                >
                  Ich akzeptiere die <a href={`/${props.PageImprint?.slug || ""}`}>Datenschutzerklärung</a>
                </label>
              </div>
              <button type="submit">
                {props?.UniversalTexts?.Submit || "Absenden"}
              </button>
            </SubmitWrapper>

          </form>}
      </ContactForm>
      <ImageWrapper>
        <Img src={imgArray?.[1]?.src} />
      </ImageWrapper>
      <ContactData />
    </FormWrapper>
  );
};

export default Contact;
