import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

import { Container } from "../Grid";

export const NewsWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 0;

  width: 100vw;
  background-color: ${({ theme }) => theme.palette.textColor};

  > h2 {
    justify-self: center;
    color: #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    font-weight: 300;
    padding-bottom: 10px;
    margin-bottom: 3rem;
  }
`;

export const NewsContainer: any = styled(Container)`
  flex-direction: column;
  margin-bottom: 3rem;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

