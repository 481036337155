import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { TSharedStylesOjb } from "../LandingImage";

interface IProps {
    sharedStylesOjb: TSharedStylesOjb;
    shouldScaleLogo: boolean;
}

const Default = css<IProps>`
    width: 100vw;
    position: fixed;
    transition: transform 0.3s linear;

    ${({ shouldScaleLogo }) =>
        shouldScaleLogo
            ? css`
                  transform: translateY(68px);
                  /* transform: translateY(calc(100px + 48px)); */
              `
            : css`
                  /* transform: translateY(68px); */
                  // 48px = padding top/bottom
                  transform: translateY(calc(100px + 48px));
              `}

    > div {
        height: 100%;
    }

    height: 100vw;
`;

const Silver = css<IProps>`
    ${({ sharedStylesOjb: { heightSliver, headerHeight } }) => css`
        height: calc(${heightSliver}vh - ${headerHeight}px);
    `}

    transform: unset;
`;

const Gold = css``;

const Platinum = css<IProps>`
    ${({ sharedStylesOjb: { heightPlatinum, headerHeight } }) => css`
        height: calc(${heightPlatinum}vh - ${headerHeight}px);
    `}
`;

const Diamond = css``;

export const SLandingImageWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
