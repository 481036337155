import React, { useContext, FC } from "react";
import { Context } from "src/common";

import RichTextGeneral from "../RichText";
import { OfficeInfoWrapper } from "./Contact.style";

const fixUrl: (url: string) => string = (url) => 
{
  if(!/https/.test(url)) 
  {
    return `https://${url}`;
  }
  return url;
};

const OfficeInfo: FC = () => 
{
  const props = useContext(Context);
  const { CompanyInformationPublic } = props;
  const facebook = CompanyInformationPublic?.facebook;
  const instagram = CompanyInformationPublic?.instagram;
  const pinterest = CompanyInformationPublic?.pintrest;
  const youtube = CompanyInformationPublic?.youtube;

  return (
    <OfficeInfoWrapper>
      <p><strong>Öffnungszeiten</strong></p>
      {CompanyInformationPublic?.openingHours && (
        <RichTextGeneral
          textContent={
            CompanyInformationPublic?.openingHours
          }
        />
      )}

      <div>
        {facebook && <a href={fixUrl("www.facebook")} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f" /></a>}
        {pinterest && <a href={fixUrl(pinterest)} target="_blank" rel="noreferrer"><i className="fab fa-pinterest" /></a>}
        {instagram && <a href={fixUrl(instagram)} target="_blank" rel="noreferrer"><i className="fab fa-instagram" /></a>}
        {youtube && <a href={fixUrl(youtube)} target="_blank" rel="noreferrer"><i className="fab fa-youtube" /></a>}

      </div>

    </OfficeInfoWrapper>
  );
};

export default OfficeInfo;
