import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { Context, getPage, ITextElement, RichText } from "src/common";
import { richToPlainText } from "../../../../../common/utils/text/plaintext";
import { Snowparticles } from "src/common/components/Snowparticles";
import { SLandingImage } from "./styles/SLandingImage";
import { SLandingImageWrapper } from "./styles/SLandingImageWrapper";
import { SLandingImageContentWrapper } from "./styles/SLandingImageContentWrapper";
import { SLandingImageHeading } from "./styles/SLandingImageHeading";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";
import { IGenAsset } from "src/common/types_dealer_gen";
import { useShouldScale } from "src/templates/t27/hooks/useShouldScaleLogo";

const sharedStylesOjb = {
    heightDefault: 100,
    heightSliver: 82,
    heightPlatinum: 100,
    headerHeight: 81,
} as const;

export type TSharedStylesOjb = typeof sharedStylesOjb;

const LandingImage: FunctionComponent = () => {
    const props = useContext(Context);
    const pageContent = getPage(props);
    const landingImage = (pageContent?.elements as any)?.find((e) => e?.__typename === "Asset") as unknown as IGenAsset;
    const firstElement = (pageContent?.elements as any)?.filter(
        (e) => e?.__typename === "TextElement"
    )?.[0] as unknown as ITextElement;
    const { shouldScale } = useShouldScale();

    if (!landingImage) {
        return null;
    }

    const getAspectRatio = (): number => {
        if (landingImage?.src && landingImage?.width && landingImage?.height) {
            return landingImage.width / landingImage.height;
        }
        return 1;
    };

    const nativeImageLowestAspectRatio = +getAspectRatio().toFixed(5);

    return (
        <SLandingImage className="t27-landing-header-area" sharedStylesOjb={sharedStylesOjb}>
            <SLandingImageWrapper
                shouldScaleLogo={shouldScale}
                className="t27-landing-image"
                id={props?.slug == null && props.Settings?.snowOnHomeImage ? `snowflakes-container` : undefined}
                sharedStylesOjb={sharedStylesOjb}
            >
                <FancyImage
                    img={landingImage}
                    lazyload={false}
                    responsiveAspectRatio={{ bronze: 320 / 320, silver: nativeImageLowestAspectRatio }}
                    // responsiveFactor={{ bronze: 0.89, silver: 0.52, platinum: 0.45 }}
                    // responsiveMaxWidth={{ platinum: 620 }}
                />
            </SLandingImageWrapper>

            {props?.slug == null && props.Settings?.snowOnHomeImage ? <Snowparticles /> : null}
            <SLandingImageContentWrapper>
                <SLandingImageHeading>
                    {firstElement?.headline && <h1>{firstElement.headline}</h1>}
                    {!!firstElement?.text && richToPlainText(firstElement.text)?.trim() !== "" && (
                        <RichText textContent={firstElement.text}></RichText>
                    )}
                </SLandingImageHeading>
            </SLandingImageContentWrapper>
        </SLandingImage>
    );
};

export default LandingImage;
