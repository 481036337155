import React, { useContext, FC } from "react";
import { Context } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { CompanyInfoWrapper } from "./Contact.style";

const CompanyInfo: FC = () => {
  const props = useContext(Context);
  const { CompanyInformationPublic } = props;
  return (
    <CompanyInfoWrapper>
      <p>
        <strong>{getContactCompanyNameShort()}</strong>
      </p>
      <p>
        {CompanyInformationPublic?.managingDirector}
      </p>
      <p>
        <div>{CompanyInformationPublic?.street}</div>
        <div>
          {CompanyInformationPublic?.postcode + " "}{" "}
          {CompanyInformationPublic?.place}
        </div>
      </p>
      {CompanyInformationPublic?.phone && (
        <p data-tel>Tel: {CompanyInformationPublic?.phone}</p>
      )}
      {CompanyInformationPublic?.fax && (
        <p data-fax>Fax: {CompanyInformationPublic?.fax}</p>
      )}
      {CompanyInformationPublic?.email && (
        <p data-email>{CompanyInformationPublic?.email}</p>
      )}
    </CompanyInfoWrapper>
  );
};

export default CompanyInfo;
