import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const MapWrapper: any = styled.div<{landingPage: boolean}>`
${({ landingPage }) =>
    landingPage &&
      `
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
      }

      `
}
`;

export const MapContainer: any = styled.div<{landingPage: boolean}>`
    height: 229px;
    margin: 20px 0 0;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      height: ${({ landingPage }) => landingPage ? "450px" : "300px"};
      margin: ${({ landingPage }) => landingPage ? "0" : "0 0 20px"};
      ${({ landingPage }) => landingPage && "width: 60%"};
    }
`;

export const serviceNumber: any = css`
    margin-bottom: 50px;
    align-items: flex-start;
    padding-left: 10px;
`;

export const RouteWrapper: any = styled.div<{landingPage: boolean}>`
    margin-top: 5px;
    display: flex;
    button {
      align-items: center;
      width: ${({ landingPage }) => landingPage ? "fit-content" : "50%"};
      margin-left: ${({ landingPage }) => landingPage ? "10px" : "14px"};
      background-color: #000;
      color: #EFEFEF;
      padding: ${({ landingPage }) => landingPage ? "5px 10px" : "10px"};
      span {
        font-size: ${({ landingPage }) => landingPage ? "13px" : "15px"};
        margin-right: 10px;
      }
    }
`;

export const LocationInput: any = styled.div<{landingPage: boolean}>`
    position: relative;
    margin-right: ${({ landingPage }) => landingPage ? "5px" : "14px"};
    width: ${({ landingPage }) => landingPage ? "38%" : "50%"};
    font-size: ${({ landingPage }) => landingPage ? "13px" : "15px"};
    input {
      padding: 6px 35px 6px 12px;
      width: 100%;
      height: 100%;
      border: ${({ landingPage }) => landingPage && "none"};
    }
    svg {
      opacity: .5;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 7px;
    }
`;

export const DirectionsPanel: any = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 600px;
  background-color: transparent;

  table.adp-placemark {
    background-color: #6d8e2d;
    border: none;
    tr > td {
      color: #fff;
      font-size: 13px;
      padding: 10.5px;
      border: none;
    }
  }
  .adp-summary {
    padding: 5px 3px 10px 3px;
    &, span {
      color: white;
      font-size: 13px;
      font-weight: 700;
    }
  }
  table:not(.adp-placemark) {
    tr {
      td {
        padding: 8px 0;
        border-top-width: 1px;
        border-top-color: rgb(109, 142, 45);
        line-height: 150%;
        color: white;
        &:first-child {
          padding-left: 5px;
          width: 10% !important;
        }
        &:nth-child(2) {
          width: 5% !important;
          font-weight: 400;
          font-size: 13px;
        }
        &:nth-child(3) {
          width: 70% !important;
          font-weight: 400;
          font-size: 13px;
        }
        &:nth-child(4) {
          width: 15% !important;
        }
      }
    }
  }
`;

export const catchmentArea: any = css`
`;

export const AddresWrapper: any = styled.div<{landingPage: boolean}>`
  ${({ landingPage }) => landingPage && `
    padding: 50px 0 0 30px;
  `}
`;
