import React, { useContext } from "react";
import { Context, ICompanyInformationPublic } from "src/common";
import { getContactCompanyNameShort } from "../../../../../../common/components/ImprintStart";

import { RichTextGeneral } from "../../RichText";
import { CompanyInformationWrapper, Address, OfficeInfo, RichTextWrapper, Logo } from "./CompanyInformation.style";

const CompanyInformation: any = () =>
{
  const props = useContext(Context);
  const companyInfo: Omit<ICompanyInformationPublic, "__typename"> = props?.CompanyInformationPublic ?? {};
  return (
    <CompanyInformationWrapper>
      <Logo src={companyInfo?.logo[0]?.src} />

      <div className="content">
        <Address>
          <span>{companyInfo.managingDirector}</span>
          <span>{getContactCompanyNameShort() }</span>
          <span>{companyInfo.street}</span>
          <span>{companyInfo.place}</span>
          <span className="phone">Tel:</span>
          <span className="phone-number">{companyInfo.phone}</span>
        </Address>

        <OfficeInfo>
          {companyInfo?.openingHours?.content && (
            <RichTextWrapper>
              <RichTextGeneral textContent={companyInfo?.openingHours?.content} />
            </RichTextWrapper>

          ) }
        </OfficeInfo>

      </div>

    </CompanyInformationWrapper>
  );
};

export default CompanyInformation;
