import React, { FC, useContext } from "react";
import { Context } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import { BrandBannerVerticalWrapper, ShadowOverlay } from "./BrandBannerVertical.style";

const BrandBannerVertical: FC = () =>
{
  const props = useContext(Context);
  const allBrands = props.AllBrand;
  // const allBrands = logos;

  if(allBrands.length <= 1)
  {
    return null;
  }

  const companies = allBrands.map((company, i) =>
  {
    if(!company?.logo)
    {
      return null;
    }
    return (
      <a key={i}>
        <Img
          src={company?.logo}
          lazyload={false}
          placeholderHeight={"31px"}
          quality={200}
          alt={company?.name || ""}
        ></Img>
      </a>
    );
  });
  const allCompanies: any[] = [];
  for(const i of Array(8).keys())
  {
    allCompanies.push(<div key={`aa${i}`}> {companies} </div>);
  }
  return (
    <BrandBannerVerticalWrapper amount={allBrands.length}>
      <div className="container">
        <div className="marquee">{allCompanies}</div>
        <div className="marquee">{allCompanies}</div>
      </div>
    </BrandBannerVerticalWrapper>
  );
};

export default BrandBannerVertical;
