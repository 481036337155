import React, { FC, useEffect, useState, useContext } from "react";
import { Context } from "src/common";

import { MapWrapper, AddressFormContainer, MapContainer, RouteWrapper, DirectionsPanel } from "./Map.style";

interface ILocation {
  lat: number;
  lng: number;
}

const AddressForm: FC<any> = ({ title, placeholder, actionCaption, onSetUserLocation }) => {
  const [inputUserLocation, setInputUserLocation] = useState("");

  const getUserLocation = (): void => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setInputUserLocation(`${position.coords.latitude},${position.coords.longitude}`);
      }, error => {
        console.warn("Standort konnte nicht abgerufen werden: ", error);
      });
    }
    else {
      console.warn("Standort konnte nicht abgerufen werden");
    }
  };

  return (
    <RouteWrapper>
      {title && <label>{title}</label>}
      <div>
        <input
          type="text"
          name={"UserLocation"}
          placeholder={placeholder || "Ihr Standort"}
          value={inputUserLocation}
          onChange={({ target: { value } }) => setInputUserLocation(value)}
        />
        <button onClick={() => onSetUserLocation(inputUserLocation)} name={"submit"}>
          {actionCaption || "Route berechnen"}
        </button>
      </div>
    </RouteWrapper>
  );
};

const mapElementId = "map";
const routePanelId = "routePanel";
const googleApiKey = process.env.NEXT_PUBLIC_MAPSAPIKEY || process.env.MAPSAPIKEY;

const setMap = (zoom: number, companyLocation: any): void => {
  if (!companyLocation) return;

  // @ts-ignore
  const windowGoogleMap = window.google.maps;

  const mapId = document.getElementById(mapElementId);
  const map = new windowGoogleMap.Map(mapId, {
    zoom,
    center: companyLocation,
    mapTypeId: windowGoogleMap.MapTypeId.ROADMAP,
    zoomControl: !0,
    zoomControlOptions: {
      style: windowGoogleMap.ZoomControlStyle.SMALL,
      position: windowGoogleMap.ControlPosition.LEFT_BOTTOM
    },
    mapTypeControl: !0,
    mapTypeControlOptions: {
      position: windowGoogleMap.ControlPosition.RIGHT_BOTTOM
    }

  });

  new windowGoogleMap.Marker({ position: companyLocation, map });
};

const getRouteDescriptions = (zoom, companyLocation: any, userLocation: any): void => {
  document.getElementById(routePanelId).innerHTML = null;

  // @ts-ignore
  const windowGoogleMap = window.google.maps;

  const directionsService = new windowGoogleMap.DirectionsService();
  const directionsRenderer = new windowGoogleMap.DirectionsRenderer();

  const map = new windowGoogleMap.Map(document.getElementById(mapElementId));

  directionsRenderer.setMap(map);
  directionsRenderer.setPanel(document.getElementById(routePanelId));

  const request = {
    origin: userLocation,
    destination: `${companyLocation.lat},${companyLocation.lng}`,
    travelMode: "DRIVING"
  };

  directionsService.route(request, (result, status) => {
    if (status === "OK") {
      directionsRenderer.setDirections(result);
    }
    else {
      setMap(zoom, companyLocation);
    }
  });
};

const injectGmaps = (callback: () => void): any => {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.src = `https://maps.google.com/maps/api/js?key=${googleApiKey}`;

  const x = document.getElementsByTagName("script")[0];
  x.parentNode.insertBefore(s, x);
  s.addEventListener("load", callback);

  return () => s.removeEventListener("load", callback);
};

const Map: FC<any> = () => {
  const props = useContext(Context);
  const companyLocation = props.PageContact && {
    lat: props.PageContact?.location.latitude,
    lng: props.PageContact?.location.longitude,
  };

  const zoom = props.PageContact?.location?.zoom || 12;

  useEffect(() => {
    if (!companyLocation) {
      return;
    }

    const callback = (): void => setMap(zoom, companyLocation);

    // @ts-ignore
    if (window.google) {
      callback();
      return;
    }

    return injectGmaps(callback);
  }, [zoom, companyLocation]);

  return (
    <MapWrapper>
      <AddressFormContainer>
        <AddressForm
          zoom={zoom}
          placeholder={props.UniversalTexts?.yourLocation}
          actionCaption={props.UniversalTexts?.CalculateRoute}
          onSetUserLocation={(userLocation) => getRouteDescriptions(zoom, companyLocation, userLocation)}
        />

        <DirectionsPanel id={routePanelId} />
      </AddressFormContainer>
      <MapContainer id={mapElementId} />
    </MapWrapper>
  );
};

export default Map;
