import {
    IGenAsset,
    IGenContinuingPageGallery,
    IGenHtmlEmbedding,
    IGenPhotoGallery,
    IGenTextElement,
} from "src/common/types_dealer_gen";

import dynamic from "next/dynamic";
import React, { FunctionComponent } from "react";
import { HtmlEmbedding } from "../../../../common/components/HtmlEmbedding/HtmlEmbedding";

export type TUniversalComponentRouter =
    | IGenAsset
    | IGenTextElement
    | IGenHtmlEmbedding
    | IGenPhotoGallery
    | IGenContinuingPageGallery;

const Asset = dynamic(async () => import("src/common/components/elements/Asset/Asset"), { ssr: false });
const TextElement = dynamic(async () => import("../../components/common/TextElement/TextElement"), { ssr: false });
const GlobalUniversalComponentsRenderer = dynamic(
    async () => import("src/common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer"),
    { ssr: false }
);

interface IUniversalComponentsRendererProps {
    components: TUniversalComponentRouter[];
    /** if false then T27Container padding will be removed */
    hasContainerPaddingWrapper?: boolean;
    /** is true if paragraphs have cRegular token instead pRegular token */
    hasCustomParagraphTokenRichtext?: boolean;
}

const UniversalComponentsRenderer: FunctionComponent<IUniversalComponentsRendererProps> = ({
    components,
    hasContainerPaddingWrapper,
    hasCustomParagraphTokenRichtext,
}) => {
    return (
        <>
            {components &&
                components.length > 0 &&
                components.map((component, index) => {
                    switch (component?.__typename) {
                        case "Asset":
                            return (
                                <Asset
                                    key={index}
                                    {...component}
                                    hasContainerPaddingWrapper={hasContainerPaddingWrapper}
                                />
                            );

                        case "HtmlEmbedding":
                                return <HtmlEmbedding key={`${index}`} {...component} />;
                        case "PhotoGallery":
                        case "ContinuingPageGallery":
                            return <GlobalUniversalComponentsRenderer key={index} items={[component]} shouldRenderNewPhotoGallery/>;

                        case "TextElement":
                            return (
                                <TextElement
                                    key={index}
                                    {...component}
                                    hasContainerPaddingWrapper={hasContainerPaddingWrapper}
                                    hasCustomParagraphTokenRichtext={hasCustomParagraphTokenRichtext}
                                />
                            );

                        default:
                            return null;
                    }
                })}
        </>
    );
};

export default UniversalComponentsRenderer;
